import React, { useEffect } from "react";
import { FLORA_SHOPIFY } from "../../assets/Links";

const Terms = () => {
  useEffect(() => {
    window.location.replace(`${FLORA_SHOPIFY}/pages/pre-order-terms/`);
  }, []);

  return <div></div>;
};

export default Terms;
